/**
 * Created by joerg on 7/17/17.
 */

// angular
import {Component} from '@angular/core';

//
import {MnHelpService} from './MnHelpService';

@Component({
    selector: 'mn-help-splitter',
    templateUrl: './MnHelpSplitter.html',
    styles: [
        `
            :host {
                height: 100%;
                width: 100%;
            }
        `
    ],
    host: {
        '[class]' : 'mTheme'
    }
})
export class MnHelpSplitter {

    mTheme:string;

    constructor(private mHelp:MnHelpService) {
        this.mTheme = this.mHelp.theme();
    }
}
