/**
 * Created by joerg on 7/24/17.
 */


"use strict";

// rxjs
import 'rxjs/Rx';
import {Subject, BehaviorSubject, Subscription, Observable,AnonymousSubject} from 'rxjs/Rx';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/distinctUntilChanged';

// angular2
import {forwardRef, Directive, Input, ElementRef, Renderer, ContentChildren, QueryList, Injectable, Injector, SkipSelf, NgZone} from "@angular/core";

// mn
import {MnBrandService, MnDeepMerge, MnLazy} from "@mn/core"

export type MnHelpPath = string[];

export interface MnHelpTopic {
    component?: any;
    title: string;
    data?: any;
    priority?: number;
    final?: boolean;
    lazy?: any;
    topics?: { [name: string]: MnHelpTopic };
}

export type MnHelpTopicIndexNumbers = number[];

export type MnHelpTopicIndexFormatter = (MnHelpTopicIndexNumbers)=>string;

let default_index_formatter:MnHelpTopicIndexFormatter = function (index_numbers:MnHelpTopicIndexNumbers):string {
    /*var result = index_numbers[0] || "";
    for (var i = 1, l = index_numbers.length; i < l; i++) {
        result+= '.'+index_numbers[i];
    }
    return result;*/
    return index_numbers.map(n=>n+1).join('.');
};

let help_topics:MnHelpTopic = {
    component: {},
    title: "Level 0",
    data: {},
    topics: {}
};

let mn_help_instance:any = null;
function help_path_changed(p: MnHelpPath, q: MnHelpPath):boolean {
    if (p === null && q === null) return true;
    else if (p !== null && q !== null) return p.join('.') == q.join('.');
    else return false;
}

@Injectable()
export class MnHelpService {
    private mInit:boolean = false;
    private mLast:MnHelpPath = null;
    //private mRequestSubject:BehaviorSubject< MnHelpPath > = new BehaviorSubject(null).distinctUntilChanged();
    private mRequestSubject:BehaviorSubject< MnHelpPath > = <BehaviorSubject<MnHelpPath>> new BehaviorSubject(null).distinctUntilChanged((p: MnHelpPath, q: MnHelpPath) => help_path_changed(p,q));

    constructor(private mInjector:Injector, private mBrands:MnBrandService) {
        if (mn_help_instance !== null) {
            throw new Error("Multiple instanciation error of MnHelp");
        }
        mn_help_instance = this;
        help_topics = MnBrandService.raw().help.topics;
        console.log("MnHelp created");
    }

    public onRequest(next?: (value: MnHelpPath) => void, error?: (error: any) => void, complete?: () => void):Subscription {
        return this.mRequestSubject.subscribe(next,error,complete);
    }

    public request(value:MnHelpPath) {
        this.mLast = value;
        this.mRequestSubject.next(value);
    }

    public current():MnHelpPath {
        //return this.mRequestSubject.getValue();
        return this.mLast;
    }

    public registerTopic(topic:MnHelpTopic) {
        help_topics = MnDeepMerge.merge(help_topics,topic);
    }

    private collectLazy(help_topic:MnHelpTopic, result:any[]) {
        for(var key in help_topic.topics) {
            var topic = help_topic.topics[key];
            if (topic.lazy) {
                result.push(topic.lazy);
            }
            this.collectLazy(topic,result);
        }
    }

    public all():Observable<MnHelpTopic> {
        if (this.mInit) {
            return Observable.of(help_topics);
        } else {
            // find all lazy help topics
            var lazy_topics:any[] = [];
            this.collectLazy(help_topics,lazy_topics);
            console.log("Lazy help topics",lazy_topics);
            if (lazy_topics.length == 0) return Observable.of(help_topics);
            return Observable.concat(lazy_topics.map(lazy_topic => MnLazy.loadLibModule(this.mInjector,lazy_topic.lib, lazy_topic.mod))).map(
                (data) => {
                    return help_topics
                },
                (error) => {
                    console.log(error);
                }
            )
        }
    }

    public all2():Observable<MnHelpTopic> {
        if (this.mInit) {
            return Observable.of(help_topics);
        } else {
            // find all lazy help topics
            var lazy_topics:any[] = [];
            this.collectLazy(help_topics,lazy_topics);
            console.log("Lazy help topics",lazy_topics);
            if (lazy_topics.length == 0) return Observable.of(help_topics);

            return Observable.fromPromise(
                Promise.all(
                    lazy_topics.map(lazy_topic => MnLazy.loadLibModulePromise(this.mInjector,lazy_topic.lib, lazy_topic.mod))
                ).then(
                (lazy_module) => {
                    console.log(lazy_module);
                    return lazy_module;
                }
            )).map(
                (data) => {
                    console.log(data);
                    this.mInit = true;
                    return help_topics
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    }

    public normalizePath(path:MnHelpPath|string):MnHelpPath {
        if (typeof path == "string") {
            return path.split('.');
        }
        return path;
    }

    public indexFormatter():MnHelpTopicIndexFormatter {
        return default_index_formatter;
    }

    public theme():string {
        return MnBrandService.raw().help.theme;
    }
}