/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import {Subscription,BehaviorSubject} from 'rxjs/Rx';

// angular
import {Component,Input,Injectable} from '@angular/core';

// mn
import {MnUnsubscribe} from "@mn/core"

//
import {MnHelpService, MnHelpTopic, MnHelpPath} from "./MnHelpService"

@Injectable()
export class MnHelpTopicService {

    private mTopicSubject:BehaviorSubject< MnHelpTopic > = new BehaviorSubject(null);
    private mPathSubject:BehaviorSubject< MnHelpPath > = new BehaviorSubject([]);

    constructor() {}

    public onTopic(next?: (value: MnHelpTopic) => void, error?: (error: any) => void, complete?: () => void):Subscription {
        return this.mTopicSubject.subscribe(next,error,complete);
    }

    public topic(value:MnHelpTopic) {
        this.mTopicSubject.next(value);
    }

    public onPath(next?: (value: MnHelpPath) => void, error?: (error: any) => void, complete?: () => void):Subscription {
        return this.mPathSubject.subscribe(next,error,complete);
    }

    public path(value:MnHelpPath) {
        this.mPathSubject.next(value);
    }


}

@Component({
    selector: 'mn-help-topic-view',
    templateUrl: './MnHelpTopicView.html',
    providers: [MnHelpTopicService]
})
@MnUnsubscribe()
export class MnHelpTopicView {

    private mTopic:MnHelpTopic = { title: "" };
    @Input()
    get topic() { return this.mTopic; }
    set topic(v: MnHelpTopic) {
        this.mTopic = v;
        this.mService.topic(this.mTopic);
    }

    private mPath:MnHelpPath;
    @Input()
    get path() { return this.mPath; }
    set path(v: MnHelpPath) {
        this.mPath = v;
        this.mService.path(this.mPath);
    }

    constructor(private mHelp:MnHelpService, private mService:MnHelpTopicService) {
        this.mService.topic(this.mTopic);
    }


    /*mHelpTopicRequest:Subscription;

    constructor(private mHelp:MnHelp) {
        this.mHelpTopicRequest = this.mHelp.onRequest(topic => this.onHelpTopicRequest(topic));
    }

    private onHelpTopicRequest(topic:MnHelpTopicRef) {

    }


    ngOnDestroy() {

    }*/
}
