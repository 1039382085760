/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import {Subscription} from 'rxjs/Rx';

// angular
import {Component} from '@angular/core';

// mn
import {MnUnsubscribe} from "@mn/core"

//
import {MnHelpService, MnHelpTopic, MnHelpPath, MnHelpTopicIndexFormatter} from "./MnHelpService"

@Component({
    selector: 'mn-help-view',
    templateUrl: './MnHelpView.html',
    styleUrls: ['./MnHelpView.css'],
    host: {
        '[class]' : 'mTheme'
    }
})
@MnUnsubscribe()
export class MnHelpView {
    //JSON = JSON;

    mTheme:string;
    mParents:any[] = [];
    mChildren:any[] = [];

    mHelpTopicRequest:Subscription;
    mIndexformatter:MnHelpTopicIndexFormatter;

    //test:string = "test.topic1.topic3.topic1";

    constructor(private mHelp:MnHelpService) {
        this.mTheme = this.mHelp.theme();
        this.mIndexformatter = this.mHelp.indexFormatter();
        this.mHelpTopicRequest = this.mHelp.onRequest(path => this.onHelpTopicRequest(path));
        //this.onHelpTopicRequest(this.test.split('.'));
    }

    private sortChildTopics(topic: MnHelpTopic):{ [name: string]: MnHelpTopic }[] {
        let result = [];
        if (topic.topics == undefined) return result;
        let topics = topic.topics;
        for(var key in topics) {
            let r = {};
            r[key] = topics[key];
            result.push(r);
        }
        result.sort((a,b) => {
            var aa = a[Object.keys(a)[0]];
            var bb = b[Object.keys(b)[0]];
            var ap = aa.priority || 9007199254740991;
            var bp = bb.priority || 9007199254740991;
            if (ap == bp) {
                if(aa.title < bb.title) return -1;
                if(aa.title > bb.title) return 1;
                return 0;
            }
            return ap - bp;
        });
        return result;
    }

    private onHelpTopicRequest(path:MnHelpPath) {
        console.log("onHelpTopicRequest()",path);
        if (path == null) return;

        this.mHelp.all2().subscribe(
            (root_help_topic) => {
                console.log(root_help_topic);
                this.updateHelpTopics(root_help_topic,path)
            },
            (error) => {
                console.log(error);
            },
            () => {
                console.log("Help Topic update complete");
            }
        );

    }

    updateHelpTopics(parent_topic:MnHelpTopic,path:MnHelpPath) {
        console.log("updateHelpTopics()",path,parent_topic);

        let ancestor_indices = [];
        let ancestor_paths = [];
        //let parent_topic:MnHelpTopic = this.mHelp.all();
        let parent_levels = 0;
        var reset_children:boolean = true;

        for (var i = 0, l = path.length; i < l; i++) {
            parent_levels++;
            let child_topic_name:string = path[i];
            let child_topic:MnHelpTopic = parent_topic.topics[child_topic_name];
            let sorted_topics = this.sortChildTopics(parent_topic);
            let final = (child_topic == undefined) || (i+1 == path.length) || (child_topic.final == true);

            let sorted_topic_index = -1;
            for (var ii = 0, ll = sorted_topics.length; ii < ll; ii++) {
                let sorted_topic = sorted_topics[ii];
                if (sorted_topic[child_topic_name]) {
                    sorted_topic_index = ii;
                    break;
                }
            }
            if (i >= this.mParents.length) {
                this.mParents.length = i+1;
            }
            if (sorted_topic_index >= 0) {
                ancestor_indices.push(sorted_topic_index);
                ancestor_paths.push(child_topic_name);
                this.mParents[i] = this.createListEntry((i+1 == path.length),ancestor_indices.slice(0),ancestor_paths.slice(0),child_topic);
            } else {
                this.mParents[i] = this.createListEntry(false,ancestor_indices.slice(0),ancestor_paths.slice(0),{ title: "Unknown Topic: " + child_topic_name });
            }

            if (i+1 == path.length) {
                reset_children = this.createChildTopics(child_topic ? child_topic : parent_topic,ancestor_indices,ancestor_paths);
            }
            parent_topic = child_topic;

            if (final) break;
        }
        if (reset_children) {
            this.mChildren.length = 0;
        }
        this.mParents.length = parent_levels;
    }

    private createListEntry(active,indices,paths,topic) {
        return {
            active: active,
            indices: indices,
            formated_indices: this.mIndexformatter(indices),
            paths: paths,
            topic: topic
        }
    }

    private createChildTopics(child_topic,ancestor_indices,ancestor_paths):boolean {
        if (child_topic && (child_topic.final == false || child_topic.final == undefined)) {
            let sorted_child_topics = this.sortChildTopics(child_topic);
            this.mChildren.length = sorted_child_topics.length;
            for (var ii = 0, ll = sorted_child_topics.length; ii < ll; ii++) {
                let sorted_topic = sorted_child_topics[ii];
                let sorted_child_topic = sorted_topic[Object.keys(sorted_topic)[0]];
                var child_indices = ancestor_indices.slice(0);
                child_indices.push(ii);
                var child_paths = ancestor_paths.slice(0);
                child_paths.push(Object.keys(sorted_topic)[0]);
                this.mChildren[ii] = this.createListEntry(false,child_indices,child_paths,sorted_child_topic);
            }
            return sorted_child_topics.length == 0;
        } else {
            return true;
        }
    }

    private onNavigate($event,paths) {
        this.mHelp.request(paths);
    }

    ngOnDestroy() {

    }
}
