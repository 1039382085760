/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import {Subscription} from 'rxjs/Rx';

// angular
import {Component} from '@angular/core';

// uirouter
//import { TransitionService } from '@uirouter/angular';

// mn
import {MnUnsubscribe} from "@mn/core";

//
import {MnHelpService,MnHelpPath} from './MnHelpService';

@Component({
    selector: 'mn-app-with-help',
    templateUrl: './MnAppWithHelp.html'
})
@MnUnsubscribe()
export class MnAppWithHelp {

    //onStateChangeUnsub:Function;

    mHelpTopicRequest:Subscription;
    mHelpPath:MnHelpPath = null;

    constructor(private mHelp:MnHelpService) {
        this.mHelpTopicRequest = this.mHelp.onRequest(path => this.onHelpTopicRequest(path));

        /*this.onStateChangeUnsub = this.mTrans.onSuccess({}, transition => {
            const to = transition.to(); // The state that was just activated
            console.log("fffffffffffffffffffffff2",to);
            if (to.data) {
                console.log("fffffffffffffffffffffff",to.data);
                // do something
            }
        });*/


    }

    private onHelpTopicRequest(path) {
        this.mHelpPath = path;
    }

    ngOnDestroy() {
        //this.onStateChangeUnsub();
    }
}
