/**
 * Created by joerg on 5/3/17.
 */

// angular
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// uirouter
import { UIRouterModule } from '@uirouter/angular';

// mn
import { MnCoreModule } from '@mn/core';
import { MnRouteModule } from '@mn/route';

//
import { MnHelpService } from './MnHelpService';
import { MnHelpView } from './MnHelpView';
import { MnHelpViewUrl } from './MnHelpViewUrl';
import { MnHelpSplitter } from './MnHelpSplitter';
import { MnHelpController } from './MnHelpController';
import { MnHelpTopicView } from './MnHelpTopicView';
import { MnAppWithHelp } from './MnAppWithHelp';
import { MnHelp } from './MnHelp';

let directives = [MnHelpView,MnHelpViewUrl,MnHelpTopicView,MnAppWithHelp,MnHelp,MnHelpController,MnHelpSplitter];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UIRouterModule.forChild(),
        MnCoreModule,
        MnRouteModule
    ],
    exports: [MnAppWithHelp,MnHelp,MnHelpController,MnHelpSplitter],
    declarations: directives,
    entryComponents: directives
})
export class MnHelpModule {
    constructor(private mHelp:MnHelpService) {
        console.log("HHHHHHHHHHHHHHHHHHHHHHHH","");
    }
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: MnHelpModule,
            providers: [MnHelpService]
        };
    }
}

export class mn_help {
    static configure():any {
        return [
            MnHelpModule.forRoot()
        ];
    }
}