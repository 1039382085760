/**
 * Created by joerg on 7/17/17.
 */

// angular
import {Component,Input,HostListener} from '@angular/core';

//
import {MnHelpPath, MnHelpService} from './MnHelpService';

@Component({
    selector: 'mn-help',
    template: `
        <span>?</span>
    `,
    styles: [
        `
            :host:hover {
                color: red;
            }
        `
    ]
})
export class MnHelp {

    private mPath:MnHelpPath = ['test'];
    @Input()
    get path() { return this.mPath; }
    set path(v: MnHelpPath) {
        this.mPath = this.mHelp.normalizePath(v);
    }

    @HostListener('click', ['$event']) onClick(e) {
        this.mHelp.request(this.mPath);
    }

    constructor(private mHelp:MnHelpService) {}
}
