/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import {Subscription} from 'rxjs/Rx';

// angular
import {Component} from '@angular/core';

// uirouter
import { Transition, StateService } from '@uirouter/angular';

// mn
import {MnUnsubscribe} from "@mn/core"

//
import {MnHelpService, MnHelpTopic, MnHelpPath, MnHelpTopicIndexFormatter} from "./MnHelpService"

@Component({
    selector: 'mn-help-view-url',
    template: '<mn-help-view style="height: 100%; width: 100%;"></mn-help-view>',
})
@MnUnsubscribe()
export class MnHelpViewUrl {

    mHelpTopicRequest:Subscription;

    constructor(private mHelp:MnHelpService, private mTrans:Transition, private mState:StateService) {
        console.log(mTrans.params());
        this.mHelpTopicRequest = this.mHelp.onRequest(path => this.onHelpTopicRequest(path));
        if (mTrans.params().path) {
            this.mHelp.request(this.mHelp.normalizePath(mTrans.params().path));
        } else {
            this.onHelpTopicRequest(this.mHelp.current());
        }
    }

    private onHelpTopicRequest(path:MnHelpPath) {
        console.log("onHelpTopicRequest()",path);
        if (path == null) return;
        var pathstring = path.join('.');
        this.mState.go('help',{path:pathstring});
    }

    ngOnDestroy() {

    }
}


