/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import {Subscription} from 'rxjs/Rx';

// angular
import {Component} from '@angular/core';

// mn
import {MnUnsubscribe} from "@mn/core"

//
import {MnHelpService, MnHelpPath} from "./MnHelpService"

@Component({
    selector: 'mn-help-controller',
    templateUrl: './MnHelpController.html'/*,
    styleUrls: ['./MnHelpView.css']*/,
    host: {
        '[class]' : 'mTheme'
    }
})
@MnUnsubscribe()
export class MnHelpController {

    mTheme:string;

    mHelpTopicRequest:Subscription;
    test:string;
    constructor(private mHelp:MnHelpService) {
        this.mTheme = this.mHelp.theme();
        this.mHelpTopicRequest = this.mHelp.onRequest(path => this.onHelpTopicRequest(path));
    }

    private onHelpTopicRequest(path:MnHelpPath) {
        console.log("onHelpTopicRequest()",path)
        if (path == null) return;
        this.test = path.join('.');
    }

    private onUpdate() {
        console.log("onTest()",this.test);
        this.mHelp.request(this.test.split('.'));
    }

    ngOnDestroy() {

    }
}
